import { useEffect } from 'react';

export const useUtmAttributes = () => {
  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);

    if (params.has('utm_medium')) {
      sessionStorage.setItem('utm_medium', params.get('utm_medium') || '');
    }

    if (params.has('utm_source')) {
      sessionStorage.setItem('utm_source', params.get('utm_source') || '');
    }

    if (params.has('utm_campaign')) {
      sessionStorage.setItem('utm_campaign', params.get('utm_campaign') || '');
    }

    if (document.referrer.includes('https://www.google.com')) {
      sessionStorage.setItem('utm_medium', 'organic');
      sessionStorage.setItem('utm_source', 'googleSearch');
    }

    if (document.referrer.includes('https://www.bing.com')) {
      sessionStorage.setItem('utm_medium', 'organic');
      sessionStorage.setItem('utm_source', 'bing');
    }
  }, []);
};
