import React, { CSSProperties, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import {
  PATH_ARTICLES,
  PATH_CUSTOMER,
  PATH_DEMO,
} from '@/constants/spa-routes';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type QuidgetScriptProps = {
  quidgetSrc?: string;
  quidgetId?: string;
  quidgetOption?: string;
  isMobile?: () => boolean;
};

const quidgetSrcInit = 'https://w.quidget.ai/quidget.loader.js';
const quidgetIdInit = 'f6dba67c-5a2d-4f20-84f4-eb3ec9cc7669';
const defaultQuidgetOption = '{}';
const quidgetOptionDemo = `{"chatView":"permanent"}`;

export const QuidgetScript = ({
  quidgetSrc = quidgetSrcInit,
  quidgetId = quidgetIdInit,
  quidgetOption = defaultQuidgetOption,
}: QuidgetScriptProps) => {
  const scriptDivRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const is1Xl = useMediaQuery('(min-width: 1440px)');
  const observerRef = useRef<MutationObserver>();

  const routesForFullScreen =
    router.pathname.includes(PATH_DEMO.CUSTOMER_DEMO) ||
    router.pathname.includes(PATH_CUSTOMER.ROOT);

  const setStylesOnElement = function (
    styles: CSSProperties,
    element: HTMLElement | null,
  ) {
    if (element) {
      Object.assign(element.style, styles);
    }
  };

  useEffect(() => {
    const handleMutations: MutationCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const cookieBanner = document?.getElementById('main-cookie-banner');
          const quidgetElement = document.getElementById('quidget');

          const cookieBannerHeight = cookieBanner?.style.height;

          if (!!cookieBanner?.clientHeight) {
            setStylesOnElement(
              { bottom: `calc(${cookieBannerHeight} + 1.5rem)` },
              quidgetElement,
            );
            return;
          } else {
            setStylesOnElement({ bottom: '1.5rem' }, quidgetElement);
            return;
          }
        }
      }
    };

    const observer = new MutationObserver(handleMutations);

    observer.observe(document.body, { childList: true, subtree: true });
    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const articlesRefreshDesktop =
      is1Xl && router.pathname.includes(`${PATH_ARTICLES.ROOT}/`);

    const quidgetNode = document?.querySelector('#quidget');

    const range = document.createRange();
    const documentFragment = range.createContextualFragment(`<script
        async
        src=${quidgetSrc}
        data-id=${quidgetId}
        data-options=${routesForFullScreen || articlesRefreshDesktop ? quidgetOptionDemo : quidgetOption}
      />`);
    if (
      scriptDivRef.current &&
      !scriptDivRef.current.innerHTML.includes('<script') &&
      !quidgetNode
    ) {
      scriptDivRef.current.append(documentFragment);
    }

    const existingScript: HTMLScriptElement | null = document.querySelector(
      'script[src="https://w.quidget.ai/quidget.loader.js"]',
    );

    const refreshHandler = (option: string) => {
      window?.quidget?.destroy && window?.quidget?.destroy();
      const script = document.createElement('script');
      script.async = true;
      script.src = quidgetSrc;
      script.setAttribute('data-id', quidgetId);
      script.setAttribute('data-options', option);

      if (existingScript) {
        existingScript.parentNode?.replaceChild(script, existingScript);
      }
    };

    if (routesForFullScreen || articlesRefreshDesktop) {
      refreshHandler(quidgetOptionDemo);
    } else {
      if (existingScript?.dataset?.options !== defaultQuidgetOption) {
        refreshHandler(defaultQuidgetOption);
      }
    }
  }, [router, is1Xl]);

  return <div ref={scriptDivRef} />;
};
